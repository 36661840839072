import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import React, { useEffect } from "react";

export default function Logout({ setPlayerId, setPlayerEmail, userHasAuthenticated, apolloClient }) {
  const history = useNavigate();

  useEffect(() => {
    async function onLoad() {
      Auth.signOut({global: true});
      setPlayerId(null);
      setPlayerEmail(false);
      userHasAuthenticated(false);
      apolloClient.apolloClient.cache.reset();
      history("/login");
    }

    onLoad();
  }, [history, setPlayerId, setPlayerEmail, userHasAuthenticated, apolloClient]);

  return <></>
}