import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useFormFields } from "../lib/hooksLib";

export default function Login({ apolloClient, userHasAuthenticated, setPlayerId, setPlayerEmail, setMarkedBoxes, setLoggingIn }) {

  useEffect(() => {
    async function onLoad() {
      setLoggingIn(true);
    }

    onLoad();
  }, [setLoggingIn]);

  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    mfaCode: "",
  });

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0 && fields.mfaCode.length === 6;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      // Log the user in
      const user = await Auth.signIn(fields.email, fields.password);
      await Auth.confirmSignIn(user, fields.mfaCode, "SOFTWARE_TOKEN_MFA");
      userHasAuthenticated(true);
      apolloClient.apolloClient.cache.reset();
      const userInfo = await Auth.currentUserInfo();
      setPlayerId(userInfo.username);
      setPlayerEmail(userInfo.attributes.email);
      setMarkedBoxes("initial");
      // Redirect to the homepage
      history("/");
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  return (
    <div className="Login">
      <h3>Login</h3>
      <Form onSubmit={handleSubmit}>
        <Form.Group size="lg" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="mfaCode">
          <Form.Label>MFA Code</Form.Label>
          <Form.Control
            type="tel"
            value={fields.mfaCode}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Button
          size="lg"
          type="submit"
          disabled={isLoading || !validateForm()}
        >
          Login
        </Button>
      </Form>
    </div>
  );
}