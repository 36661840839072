import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify, Auth } from "aws-amplify";
import { BrowserRouter as Router } from "react-router-dom";
import { setContext } from '@apollo/client/link/context'

import {
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
} from "@apollo/client";

// Init Amplify
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "userpool",
        region: process.env.REACT_APP_REGION,
        endpoint: process.env.REACT_APP_ADMIN_API_URL,
      },
    ],
  },
});

const authMiddleware = setContext(async (_, { headers }) => {
  try {
    const currentSession = await Auth.currentSession();

    const accessToken = currentSession.getAccessToken();
    const accessJwtToken = accessToken.getJwtToken()

    const idToken = currentSession.getIdToken();
    const idJwtToken = idToken.getJwtToken()

    const userInfo = await Auth.currentUserInfo();

    if (!userInfo) {
      throw new Error('User info not available');
    }

    return {
      headers: {
        ...headers,
        authorization: accessJwtToken || null,
        id_token: idJwtToken || null,
      },
    }
  } catch (err) {
    return {
      headers,
    }
  }
})

const publicHttpLink = new HttpLink({ uri: process.env.REACT_APP_PUBLIC_API_URL })
const privateHttpLink = new HttpLink({ uri: process.env.REACT_APP_PRIVATE_API_URL })



const link = ApolloLink.from([
  authMiddleware,
  ApolloLink.split(
    // If the context is private, use the private API, otherwise use the public
    operation => operation.getContext().clientName === "private",
    privateHttpLink,
    publicHttpLink,
  )
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Router>
        <App apolloClient={client}/>
      </Router>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
