import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useFormFields } from "../lib/hooksLib";

export default function ResetMfa({ setLoggingIn}) {
  useEffect(() => {
    async function onLoad() {
      setLoggingIn(true);
    }

    onLoad();
  }, [setLoggingIn]);

  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
  });
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.password.length > 0
    );
  }

  async function handleEmailSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const user = await Auth.signIn(fields.email, fields.password);
      await sendDeleteRequest(user.username);
      history("/signup");
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  async function sendDeleteRequest(username) {
    const response = await fetch(`${process.env.REACT_APP_ADMIN_API_URL}/userpool-delete`, {
      method: "POST",
      body: JSON.stringify({
        "username": username
      })
    });
    if (response.ok) {
      return await response.text();
    } else {
      throw new Error(await response.text());
    }
  }

  function renderEmailForm() {
    return (
      <Form onSubmit={handleEmailSubmit}>
        <Form.Group controlId="email" size="lg">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Button
          size="lg"
          type="submit"
          variant="success"
          disabled={isLoading || !validateForm()}
        >
          Reset
        </Button>
      </Form>
    );
  }

  return (
    <div className="ResetMfa">
      <h3>Resetting MFA</h3>
      <h5>NOTE: This will delete your user and a new one will have to be created</h5>
      {renderEmailForm()}
    </div>
  );
}