import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import { useEffect } from "react";

export default function Guest({ setPlayerId }) {
  const history = useNavigate();
  useEffect(() => {
    async function onLoad() {
      try {
        const playerId = Math.floor(Math.random() * 100000) + 1 ;
        const cookies = new Cookies();
        cookies.set('playerId', playerId, { path : '/' });
        setPlayerId(playerId);
        history("/");
      } catch (e) {
      }
    }

    onLoad();
  }, [history, setPlayerId]);
}