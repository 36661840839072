import React, { useEffect } from "react";

export default function BoardContainer({ setLoggingIn }) {
  useEffect(() => {
    async function onLoad() {
      setLoggingIn(false);
    }

    onLoad();
  }, [setLoggingIn]);

  return ( <></> )
}