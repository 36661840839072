import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useFormFields } from "../lib/hooksLib";
import QRCode from 'qrcode.react';
import copy from 'copy-to-clipboard';

export default function Signup({ apolloClient, userHasAuthenticated, setPlayerId, setPlayerEmail, setMarkedBoxes, setJwtToken, setLoggingIn }) {
  useEffect(() => {
    async function onLoad() {
      setLoggingIn(true);
    }

    onLoad();
  }, [setLoggingIn]);

  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
    mfaCode: "",
  });
  const history = useNavigate();
  const defaultCopyButtonText = "Copy MFA Code to Clipboard";
  const [newUser, setNewUser] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mfaCode, setMfaCode] = useState(null);
  const [copyButtonText, setCopyButtonText] = useState(defaultCopyButtonText);
  const [displayMfaCode, showMfaCode] = useState(false);

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }

  function validateMfaForm() {
    return fields.mfaCode.length === 6;
  }

  // Initial Signup Form
  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      // Sign up the user
      const newUser = await Auth.signUp({
        username: fields.email,
        password: fields.password,
      });
      setIsLoading(false);
      setNewUser(newUser);
      setMarkedBoxes("initial");
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  // Email Confirmation Code Submit
  async function handleConfirmationSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      // Check the user's confirmation code
      await Auth.confirmSignUp(fields.email, fields.confirmationCode);
      // Sign the user in
      const authUser = await Auth.signIn(fields.email, fields.password);
      const totpResponse = await Auth.setupTOTP(authUser);
      setUser(authUser);
      setMfaCode(totpResponse);
      setIsLoading(false);
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  // MFA Confirmation Submit
  async function handleMfaSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.verifyTotpToken(user, fields.mfaCode);
      Auth.setPreferredMFA(user, 'TOTP');
      // const session = await Auth.currentSession();
      // const accessToken = session.getAccessToken();
      // setJwtToken(accessToken.getJwtToken());
      userHasAuthenticated(true);
      apolloClient.apolloClient.cache.reset();
      const userInfo = await Auth.currentUserInfo();
      setPlayerId(userInfo.username);
      setPlayerEmail(userInfo.attributes.email);
      setMarkedBoxes("initial");
      // Redirect to the homepage
      history("/");
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  function renderConfirmationForm() {
    return (
      <Form onSubmit={handleConfirmationSubmit}>
        <Form.Group controlId="confirmationCode" size="lg">
          <Form.Label>Emailed Confirmation Code</Form.Label>
          <Form.Control
            autoFocus
            type="tel"
            onChange={handleFieldChange}
            value={fields.confirmationCode}
          />
          <Form.Text muted>Please check your email for the code.</Form.Text>
        </Form.Group>
        <Button
          size="lg"
          type="submit"
          variant="success"
          disabled={isLoading || !validateConfirmationForm()}
        >
          Verify
        </Button>
      </Form>
    );
  }

  function renderForm() {
    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="email" size="lg">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group controlId="password" size="lg">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group controlId="confirmPassword" size="lg">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            onChange={handleFieldChange}
            value={fields.confirmPassword}
          />
        </Form.Group>
        <Button
          size="lg"
          type="submit"
          variant="success"
          disabled={isLoading || !validateForm()}
        >
          Signup
        </Button>
      </Form>
    );
  }

  function copyText() {
    copy(mfaCode);
    setCopyButtonText("Copied!");
    setTimeout(() => {
      setCopyButtonText(defaultCopyButtonText);
    }, 5000);
  }

  function toggleMfa() {
    showMfaCode(!displayMfaCode);
  }

  function renderMfaForm() {
    const qrString = `otpauth://totp/${process.env.REACT_APP_SUB_DOMAIN}: ${fields.email}?secret=${mfaCode}&issuer=${process.env.REACT_APP_DOMAIN}`;
    return (
      <Form onSubmit={handleMfaSubmit}>
        <Form.Group controlId="mfaCode" size="lg">
          <Form.Label>MFA Code</Form.Label>
          <div><QRCode value={qrString}/></div>
          <div><Button onClick={copyText}>
            {copyButtonText}
          </Button></div>
          <div><Button onClick={toggleMfa}>{displayMfaCode ? "Hide MFA Code" : "Show MFA Code"}</Button></div>
          {displayMfaCode ? (
            <div className="displayMfaDiv"><Form.Control
              disabled
              autoFocus
              type="tel"
              value={mfaCode}
            /></div>
          ) : <></> }
          <Form.Control
            autoFocus
            type="tel"
            onChange={handleFieldChange}
            value={fields.mfaCode}
          />
        </Form.Group>
        <Button
          size="lg"
          type="submit"
          variant="success"
          disabled={isLoading || !validateMfaForm()}
        >
          Verify
        </Button>
      </Form>
    );
  }

  return (
    <div className="Signup">
      <h3>Signup</h3>
      {newUser === null ? renderForm() : mfaCode === null ? renderConfirmationForm() : renderMfaForm()}
    </div>
  );
}