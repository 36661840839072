import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import { Auth } from "aws-amplify";
import { gql, useQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from "react";
import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Route, Routes } from "react-router-dom";
// import { API } from "aws-amplify";
import Cookies from 'universal-cookie';

import Login from "./components/Login";
import Logout from "./components/Logout";
import Signup from "./components/Signup";
import Reset from "./components/Reset";
import ResetMfa from "./components/ResetMfa";
import BoardContainer from "./components/BoardContainer";
import Guest from "./components/Guest";

function App(apolloClient) {

  // const [/*jwtToken,*/ setJwtToken] = useState(null);
  const [markedBoxes, setMarkedBoxes] = useState("initial");
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [playerId, setPlayerId] = useState(false);
  const [playerEmail, setPlayerEmail] = useState(false);
  const [boardId, setBoardId] = useState(null);
  const [generatingBoard, setGeneratingBoard] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const [graphQlClient, setGraphQlClient] = useState("public");

  const routeProps = {
    apolloClient,
    isAuthenticated,
    userHasAuthenticated,
    playerId,
    setPlayerId,
    playerEmail,
    setPlayerEmail,
    setMarkedBoxes,
    // setJwtToken,
    loggingIn, setLoggingIn
  };


  let boxes;
  let markedJson;

  useEffect(() => {
    async function onLoad() {
      try {
        await Auth.currentSession();
        // const session = await Auth.currentSession();
        // const accessToken = session.getAccessToken();
        // setJwtToken(accessToken.getJwtToken());
        const userInfo = await Auth.currentUserInfo();
        userHasAuthenticated(true);
        setPlayerId(userInfo.username);
        setPlayerEmail(userInfo.attributes.email);
        setMarkedBoxes("initial");
        setGraphQlClient("private");
      } catch (e) {
        if (e !== "No current user") {
          alert(e);
        } else {
          const cookies = new Cookies();
          const cookieId = cookies.get('playerId');

          if (cookieId) {
            setPlayerId(cookieId);
          }
        }
      }

      setIsAuthenticating(false);
    }

    onLoad();
  }, []);

  /*
  function getAuthHeaders() {
    return {
      headers: {
        Authorization: jwtToken
      }
    }
  }

  function sendTestRequest() {
    if (jwtToken) {
      API.get("userpool", "/userpool", getAuthHeaders());
    }
  }
  */

  const GET_BOARDS_FOR_PLAYER = gql `
    query {
      getBoardByPlayerId (playerId: "${playerId}") {
        id,
        playerId,
        boxes,
        marked
      }
    }
  `;

  const GENERATE_BOARD = gql `
    mutation GenerateNewBoard {
      generateNewBoard(playerId: "${playerId}") {
        id,
        playerId,
        boxes,
        marked
      }
    }
  `;

  const DELETE_BOARD = gql `
    mutation DeleteBoard($boardId: String!) {
      deleteBoard(boardId: $boardId)
    }
  `;

  const UPDATE_BOARD_BOXES = gql `
    mutation UpdateBoardMarked ($boardId: String!, $markedBoxes: String!) {
      updateBoardMarked(boardId: $boardId, markedBoxes: $markedBoxes) {
        id,
        playerId,
        boxes,
        marked
      }
    }
  `;

  const GET_ITEM_QUERY = gql `
    query GetItem($itemId: String!) {
      getItem(itemId: $itemId) {
        id
        name
        imageUrl
      }
    }
  `;

  function DisplayItem(params) {
    const itemId = params.itemId[0];
    const position = params.itemId[1];

    const { loading, error, data } = useQuery(GET_ITEM_QUERY, {
      variables: { itemId: itemId },
      context: {clientName: graphQlClient},
    });

    let text = "";
    let imageSource = "";
    if (loading) text="Loading...";
    if (text === "" && error) text="There was an error";

    if(text === "" && !data.getItem) {
      return <p>Item Unavailable</p>
    }

    if(text === "") {
      text = data.getItem.name;
      const itemUrl = data.getItem.imageUrl;
      if(itemUrl) {
        imageSource = "/items/" + itemUrl;
      }
    }

    const boxId = "item-" + position;
    let itemClasses = ["tableCell", "itemBox"];
    if (markedBoxes.includes(position.toString())) {
      itemClasses.push("itemBoxClicked");
    }

    return <div id={boxId} data-position={position} data-item-id={itemId} className={itemClasses.join(" ")} onClick={ItemBoxClicked}>
      <div className="itemText" data-position={position}>{text}</div>
      <div className="itemImage" data-position={position}>{imageSource ? <img data-position={position} alt={text} src={imageSource}/> : ""}</div>
    </div>
  }

  function BoardArea() {
    useEffect(_=> {
      if(markedBoxes === "initial") {
        setMarkedBoxes(markedJson ? JSON.parse(markedJson) : []);
      }
    });

    return <div className="boardArea">
      <div className="table">
        <div className="tableRow">
          <DisplayItem itemId={[boxes[0], 0]} />
          <DisplayItem itemId={[boxes[1], 1]} />
          <DisplayItem itemId={[boxes[2], 2]} />
        </div>
        <div className="tableRow itemBox">
          <DisplayItem itemId={[boxes[3], 3]} />
          <DisplayItem itemId={[boxes[4], 4]} />
          <DisplayItem itemId={[boxes[5], 5]} />
        </div>
        <div className="tableRow itemBox">
          <DisplayItem itemId={[boxes[6], 6]} />
          <DisplayItem itemId={[boxes[7], 7]} />
          <DisplayItem itemId={[boxes[8], 8]} />
        </div> 
      </div>
    </div>
  }

  function DisplayBoard() {
    const { loading, error, data } = useQuery(
      GET_BOARDS_FOR_PLAYER,
      {context: {clientName: graphQlClient}},
    );

    useEffect(_=> {
      if(!loading && !error) {
        if (!boardId || !data.getBoardByPlayerId) {
          if (data.getBoardByPlayerId) {
            setBoardId(data.getBoardByPlayerId.id);
            setGeneratingBoard(false);
          } else {
            if(!generatingBoard) {
              setGeneratingBoard(true);
              createBoard();
              setBoardId(null);
              setMarkedBoxes([]);
            }
          }
        }
      }
    });

    // TODO Remove once we are actually using this functionality
    // sendTestRequest();

    if (loading) return "Loading board information..."
    if (error) return "There was an error loading board information!"

    if (!boardId || !data.getBoardByPlayerId) {
      return `Generating a board...`
    }

    boxes = JSON.parse(data.getBoardByPlayerId.boxes);
    markedJson = data.getBoardByPlayerId.marked;

    return <BoardArea />
  }

  const [updateBoardBoxes] = useMutation(
    UPDATE_BOARD_BOXES,
    {context: {clientName: graphQlClient}},
  );

  function getItemBoxDiv(event) {
    const parentNode = event.target.parentNode;
    if(parentNode.className && parentNode.className.includes("itemBox")) {
      return parentNode;
    } else {
      return parentNode.parentNode;
    }
  }

  function ItemBoxClicked(e) {
    const itemPosition = e.target.dataset.position;
    const position = markedBoxes.indexOf(itemPosition);

    const itemBox = getItemBoxDiv(e);

    let newMarkedBoxes = [...markedBoxes];
    if (position < 0) {
      itemBox.className = itemBox.className + " itemBoxClicked";
      newMarkedBoxes.push(itemPosition);
    } else {
      itemBox.className = itemBox.className.replace("itemBoxClicked", "");
      newMarkedBoxes.splice(markedBoxes.indexOf(itemPosition), 1);
    }
    setMarkedBoxes(newMarkedBoxes);
    updateBoardBoxes({variables: { boardId: boardId, markedBoxes: JSON.stringify(newMarkedBoxes) }} );
  }

  const [createBoard] = useMutation(GENERATE_BOARD, {
    context: {clientName: graphQlClient},
    refetchQueries: [
      {query: GET_BOARDS_FOR_PLAYER},
    ]
  });

  const [deleteBoard] = useMutation(DELETE_BOARD, {
    context: {clientName: graphQlClient},
    refetchQueries: [
      {query: GET_BOARDS_FOR_PLAYER},
    ]
  });

  function deleteExistingBoard() {
    deleteBoard({variables: { boardId: boardId }});
  }

  return (
    !isAuthenticating && (
      <div className="App">
        <div className="header">
          <header className="App-header">
            <p>
              Car Bingo! { process.env.REACT_APP_STAGE === "prod" ? null : `(${process.env.REACT_APP_STAGE} environment)` }
            </p>
          </header>
          <span className="link-header">
            <div className="App">
              <Navbar>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                  <Nav activeKey={window.location.pathname}>
                    {playerId || isAuthenticated ? (
                      <>
                        {boardId && !generatingBoard ? (
                          <>
                            { loggingIn ? (
                              <LinkContainer to="/">
                                <Nav.Link>Back to Board</Nav.Link>
                              </LinkContainer>
                            ) : (
                              <Nav.Link onClick={deleteExistingBoard}>Generate New Board</Nav.Link>
                            ) }
                          </>
                        ) : (
                          <></>
                        ) }
                        {isAuthenticated ? (
                          <>
                            <LinkContainer to="/logout">
                              <Nav.Link>Logout</Nav.Link>
                            </LinkContainer>
                          </>
                        ) : ( <></> ) }
                      </>
                    ) : ( <></> ) }
                    { !isAuthenticated ? (
                      <>
                        { ! playerId ? (
                          <LinkContainer to="/guest">
                            <Nav.Link>Start as Guest</Nav.Link>
                          </LinkContainer>
                        ) : (
                          <></>
                        ) }
                        <LinkContainer to="/signup">
                          <Nav.Link>Signup</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/login">
                          <Nav.Link>Login</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/reset">
                          <Nav.Link>Reset Password</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/mfa">
                          <Nav.Link>Reset MFA</Nav.Link>
                        </LinkContainer>
                      </>
                    ) : ( <></> ) }
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </div>
          </span>
        </div>
        <Routes>
          <Route path="/" element={<BoardContainer {...routeProps}/>} />
          <Route exact path="/signup" element={<Signup {...routeProps}/>} />
          <Route exact path="/login" element={<Login {...routeProps}/>} />
          <Route exact path="/logout" element={<Logout {...routeProps}/>} />
          <Route exact path="/reset" element={<Reset {...routeProps}/>} />
          <Route exact path="/mfa" element={<ResetMfa {...routeProps}/>} />
          <Route exact path="/guest" element={<Guest {...routeProps}/>} />
        </Routes>
        {(playerId || isAuthenticated) && !loggingIn ? (
          <div className="boardContainer">
            <div>Player: {playerEmail || playerId}</div>
            <DisplayBoard/>
          </div>
        ) : (
          <></>
        )}
      </div>
    )
  );
}

export default App;
