import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useFormFields } from "../lib/hooksLib";

export default function Reset({ apolloClient, userHasAuthenticated, setPlayerId, setPlayerEmail, setMarkedBoxes, setLoggingIn }) {

  useEffect(() => {
    async function onLoad() {
      setLoggingIn(true);
    }

    onLoad();
  }, [setLoggingIn]);

  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
    mfaCode: "",
  });
  const history = useNavigate();
  const [newUser, setNewUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return (
      fields.email.length > 0
    );
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword &&
      fields.mfaCode.length === 6;
  }

  async function handleEmailSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      // Sign up the user
      const newUser = await Auth.forgotPassword(
        fields.email
      );
      setIsLoading(false);
      setNewUser(newUser);
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  // Email Confirmation Code Submit
  async function handleConfirmationSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      // Check the user's confirmation code
      await Auth.forgotPasswordSubmit(
        fields.email,
        fields.confirmationCode,
        fields.password,
      );
      const authUser = await Auth.signIn(fields.email, fields.password);

      await Auth.confirmSignIn(authUser, fields.mfaCode, "SOFTWARE_TOKEN_MFA");
      userHasAuthenticated(true);
      apolloClient.apolloClient.cache.reset();
      const userInfo = await Auth.currentUserInfo();
      setPlayerId(userInfo.username);
      setPlayerEmail(userInfo.attributes.email);
      setMarkedBoxes("initial");
      history("/");
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  function renderConfirmationForm() {
    return (
      <Form onSubmit={handleConfirmationSubmit}>
        <Form.Group controlId="confirmationCode" size="lg">
          <Form.Label>Emailed Confirmation Code</Form.Label>
          <Form.Control
            autoFocus
            type="tel"
            onChange={handleFieldChange}
            value={fields.confirmationCode}
          />
          <Form.Text muted>Please check your email for the code.</Form.Text>
        </Form.Group>
        <Form.Group controlId="password" size="lg">
          <Form.Label>New Password</Form.Label>
          <Form.Control
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group controlId="confirmPassword" size="lg">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            onChange={handleFieldChange}
            value={fields.confirmPassword}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="mfaCode">
          <Form.Label>MFA Code</Form.Label>
          <Form.Control
            type="tel"
            value={fields.mfaCode}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Button
          size="lg"
          type="submit"
          variant="success"
          disabled={isLoading || !validateConfirmationForm()}
        >
          Update
        </Button>
      </Form>
    );
  }

  function renderEmailForm() {
    return (
      <Form onSubmit={handleEmailSubmit}>
        <Form.Group controlId="email" size="lg">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Button
          size="lg"
          type="submit"
          variant="success"
          disabled={isLoading || !validateForm()}
        >
          Reset
        </Button>
      </Form>
    );
  }

  return (
    <div className="Reset">
      <h3>Resetting Password</h3>
      {newUser === null ? renderEmailForm() : renderConfirmationForm()}
    </div>
  );
}